import { Link, useLocation, useNavigate} from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useState, useEffect, useContext } from "react";
import { Tab, Tabs, TabList } from "react-tabs";
import Cookies from "js-cookie";
import Avatar from 'react-avatar';
import { UserContext } from "../../userProvider";
import profilePicture from "../../image/profile-picture.png"
function Header() {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  let language = i18n.language || 'en'
  const { userImage, logout } = useContext(UserContext);
  const [userEmail, setUserEmail] = useState('')
  const [userData, setUserData] = useState()

  const changeLanguage = (language) => {
  i18n.changeLanguage(language, () => {
    Cookies.set('selectedLanguage', language, { expires: 365 });
    const currentPath = location.pathname;
    const newPath = currentPath.replace(/^\/(en|ja|vi|ko)/, `/${language}`);
    if (currentPath !== newPath) {
      navigate(newPath, { replace: true });
    }
  });
};
  useEffect(() => {
    const savedLang = Cookies.get('selectedLanguage');
    if(savedLang && i18n.language !== savedLang){
      i18n.changeLanguage(savedLang)
      }
  })
  const feature = t("feature.feature_item", { returnObjects: true });
  const hcm = t("hcm-branch.branch", {returnObjects:true})
  const hn = t("hn-branch.branch", {returnObjects:true})
  const dn = t("dn-branch.branch", {returnObjects:true})
  const hp = t("hp-branch.branch", {returnObjects:true})
  const service = t("service.service_name", {returnObjects:true})
  const [isOpen, setIsOpen] = useState(false)
       
  const [backgroundColor, setBackgroundColor] = useState('transparent');
  const isProduct = process.env.NODE_ENV === 'production'
    useEffect(() => {
      const handleScroll = () => {
        const position = window.scrollY;
        if (position > 100) {
          setBackgroundColor('#482979'); 
        } else {
          setBackgroundColor('transparent'); 
        }
      };

      window.addEventListener('scroll', handleScroll);
      return () => {
        window.removeEventListener('scroll', handleScroll);
      };
    }, []);
  const toggleHeader = () => {
      setIsOpen(!isOpen)
  }
  useEffect(()=>{
    if(isOpen) {
      document.body.style.position= "fixed";
    }else{
      document.body.style.position = "";
    }
  })
;
  const HeaderMobile = () => {
    return(
    <ul className={`header__mobile-navbar-list ${isOpen ? 'open' :''}`}>
          <button
            onClick={()=> setIsOpen(false)}
            className="header__mobile-navbar-closed"
          >
            <i className="fa-solid fa-xmark"></i>
          </button>

          {/* <li className="header__mobile-navbar-item">
          {userData ? (
                          <div className='header__mobile-navbar-link' style={{padding: 20}}>
                          <div className="profile-container d-flex align-items-center">
                          <img className="profile-image" src={userImage} alt="Profile" style={{width: 30, height: 30,  marginRight: 10, borderRadius: '50%'}}/>
                          <p className="mb-0" style={{color: "#000"}}>{userData.displayName || userData.name}</p>
                          </div>
                          <div className="d-flex align-items-center mt-3">
                          <Link to = '/lang:/setting' className="profile-btn"><i class="fa-solid fa-gear" style={{color: '#fff'}}></i></Link>
                          <div>Setting</div>
                          </div>
                          <div className="d-flex align-items-center mt-3">
                          <Link to = '/lang:/setting' className="profile-btn" style={{backgroundColor: '#fff'}}><i class="fa-solid fa-right-from-bracket"style={{color: '#482979', fontSize:'2.2rem'}}></i></Link>
                          <div>Log out</div>
                          </div>
                          </div>
                        ) : (
                        <div className='btn-lang col-md-3'>
                        <a href="/login" style={{textDecoration: 'none', color:'#482979', backgroundColor: "#fff", fontSize: '1.4rem', fontWeight:'bold', paddingLeft: 20, paddingRight: 20, borderRadius: 5}}>Login</a>
                        </div>
                        )}
          </li> */}
          <li className="header__mobile-navbar-item">
            <Link
              onClick={()=> setIsOpen(false)}
              Link
              to={`/${language}/`}
              className="header__mobile-navbar-link"
            >
              {t("header.home")}
            </Link>
          </li>
          <li className="header__mobile-navbar-item">
            <Link
              onClick={()=> setIsOpen(false)}
              Link
              to={`/${language}/news/`}
              className="header__mobile-navbar-link"
            >
              {t("header.news")}
            </Link>
          </li>
          <li className="header__mobile-navbar-item">
            <Link
              to={`/${language}/hotel-hn/`}
              onClick={()=> setIsOpen(false)}
              className="header__mobile-navbar-link"
            >
              {t("header.hn")}
            </Link>
          </li>
          <li className="header__mobile-navbar-item">
            <Link
              to={`/${language}/hotel-hcm/`}
              onClick={()=> setIsOpen(false)}
              className="header__mobile-navbar-link"
            >
              {t("header.hcm")}
            </Link>
          </li>
          <li className="header__mobile-navbar-item">
            <Link
              to={`/${language}/hotel-dn/`}
              onClick={()=> setIsOpen(false)}
              className="header__mobile-navbar-link"
            >
              {t("header.dn")}
            </Link>
          </li>
          <li className="header__mobile-navbar-item">
            <Link
              to={`/${language}/hotel-hp/`}
              onClick={()=> setIsOpen(false)}
              className="header__mobile-navbar-link"
            >
              {t("header.hp")}
            </Link>
          </li>
          <li className="header__mobile-navbar-item">
            <Link
              to="http://azumayacambodia.com"
              onClick={()=> setIsOpen(false)}
              className="header__mobile-navbar-link"
            >
              {t("header.cambodia")}
            </Link>
          </li>
          <li className="header__mobile-navbar-item">
            <Link
              to={`/${language}/reservation/`}
              onClick={()=> setIsOpen(false)}
              className="header__mobile-navbar-link"
            >
              {t("header.reservation")}
            </Link>
          </li>
          <li className="header__mobile-navbar-item">
            <Link
              to={`/${language}/feature/`}
              onClick={()=> setIsOpen(false)}
              className="header__mobile-navbar-link"
            >
              {t("header.feature")}
            </Link>
          </li>
          <li className="header__mobile-navbar-item">
            <Link
              to={`/${language}/service/`}
              onClick={()=> setIsOpen(false)}
              className="header__mobile-navbar-link"
            >
              {t("header.service")}
            </Link>
          </li>
          <li className="header__mobile-navbar-item">
            <Link
              to={`/${language}/breakfast/`}
              onClick={()=> setIsOpen(false)}
              className="header__mobile-navbar-link"
            >
              {t("header.breakfast")}
            </Link>
          </li>
          <li className="header__mobile-navbar-item">
            <Link
              to={`/${language}/rotenburo/`}
              onClick={()=> setIsOpen(false)}
              className="header__mobile-navbar-link"
            >
              {t("header.roten")}
            </Link>
          </li>
          <li className="header__mobile-navbar-item">
            <Link
              to={`/${language}/massage/`}
              onClick={()=> setIsOpen(false)}
              className="header__mobile-navbar-link"
            >
              {t("header.massage")}
            </Link>
          </li>
          {/* <li className="header__mobile-navbar-item">
            <Link
              to="/Massage"
              onClick={()=> setIsOpen(false)}
              className="header__mobile-navbar-link"
            >
              {t("header.massage")}
            </Link>
          </li> */}
          <li className="header__mobile-navbar-item">
            <Link
              to={`/${language}/contract/`}
              onClick={()=> setIsOpen(false)}
              className="header__mobile-navbar-link"
            >
              {t("header.contract")}
            </Link>
          </li>
          <li className="header__mobile-navbar-item">
            <Link
              to={`/${language}/q&a`}
              onClick={()=> setIsOpen(false)}
              className="header__mobile-navbar-link"
            >
              {t("header.policies")}
            </Link>
          </li>
        </ul>
    )
  }

  const [active, setActive] = useState(false)
  const handleActive = () => {
    setActive(!active)
  }
 
  const handleLogout = () => {
    logout();
    setIsOpen(false)
    navigate(`/${language}/`);
    window.location.reload();
  };

  const postData = async (data) => {
    try {
      const response = await fetch('http://localhost:3000/api/user/wIzo9Vlr-GIpz-cuQ9-R1Q8-9I5KIyEFShl', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data)
      
      });
      
      if (!response.ok) {
      throw new Error('Network response was not ok');
      }
      const responseData = await response.json();
      setUserData(responseData)
    } 
    catch (error) {
      console.error('Error:', error);
    }
}

useEffect(() => {
  const getEmail = () => {
    const savedUserData = localStorage.getItem('userData');
    if (savedUserData) {
      setUserEmail(JSON.parse(savedUserData));
    }
  };

  getEmail();
}, []); 

useEffect(() => {
  if (userEmail) {
    postData({id: userEmail.data.id}); 
  }
}, [userEmail]); 
  return (
    <>
      <div className='top-header'>
                <div className='container-fluid'>
                    <div className='top-header-container align-items-center justify-content-center'>
                        <div className='col-md-9'>
                        <p className="top-header-text">{t('header.top-header')}</p>
                     
                        </div>
                        {userData ? (
                          <div className='btn-lang col-md-3'>
                          <button className ='btn-en mr-3' onClick={()=>{changeLanguage('en')}}></button>                
                          <button className ='btn-ja mr-3' onClick={()=>{changeLanguage('ja')}}></button>  
                          <button className ='btn-vie mr-3' onClick={()=>{changeLanguage('vi')}}></button> 
                          <button className ='btn-kor mr-3' onClick={()=>{changeLanguage('ko')}}></button> 
                          {!isProduct &&
                          <div className="profile-container-1">
                          <p style={{color: "#fff", marginRight: 10}}>{userData.data.name}</p>
                          <img className="profile-image" src={userImage || userData.data.avatar || profilePicture} alt="Profile" style={{width: 30, height: 30, borderRadius: '50%'}}/>
                          <ul className="header__navbar-item-list" style={{width:200, top: 45, right: 20, borderBottomLeftRadius: 10, borderBottomRightRadius:10, zIndex:999, boxShadow: 'rgba(0, 0, 0, 0.24) 0px 3px 8px'}}>
                          <li className = "header__navbar-sub-link" style={{backgroundColor: '#fff'}} >
                          <Link style={{color: "#000"}} to ='#' onClick={() => {
                            navigate(`/${language}/setting/`)
                            window.location.reload()
                            }}><i class="fa-solid fa-gear" style={{color: '#482979', marginRight: 10}}></i>{t("login.setting")}</Link>
                          </li>
                          <li className = "header__navbar-sub-link"style={{backgroundColor: '#fff', borderBottomLeftRadius: 10, borderBottomRightRadius:10, }}>
                          <Link  style={{color: "#000"}} onClick={handleLogout}><i class="fa-solid fa-right-from-bracket"style={{color: '#482979',marginRight: 10}}></i>{t("login.logout")}</Link>
                          </li>
                          </ul>
                          </div>}
                          </div>
                        ) : (
                        <div className='btn-lang col-md-3'>
                        <button className ='btn-en mr-3' onClick={()=>{changeLanguage('en')}}></button>                
                        <button className ='btn-ja mr-3' onClick={()=>{changeLanguage('ja')}}></button>  
                        <button className ='btn-vie mr-3' onClick={()=>{changeLanguage('vi')}}></button>  
                        <button className ='btn-kor mr-3' onClick={()=>{changeLanguage('ko')}}></button>  
                        {!isProduct &&
                        <a href={`/${language}/login`} style={{textDecoration: 'none', color:'#482979', backgroundColor: "#fff", fontSize: '1.4rem', fontWeight:'bold', paddingLeft: 20, paddingRight: 20, borderRadius: 5}}>{t("login.login")}</a>
                        }
                        </div>
                        )}
                    </div>
                </div>
            </div>
      <div className="header_container">
      <div className="header-2">
        <div className="header__PC-2">
          <div className="header__logo">
            <div className="container">
              <div className="row">
                <div className="col-md-12">
                  <Link to="/">
                    <img
                      src="/webp/azlogo.webp"
                      alt="logo"
                    />
                  </Link>
                </div>
              </div>
            </div>
          </div>
          {/* <ul className="header__navbar-list-2">
            <li className="header__navbar-item header-reserve"> 
              <Link
                className={`header__navbar-link header-reserve ${active? 'tab_active' : ''}`}
                onClick={handleActive}
                onBlur={(e)=>setActive(false)}
                to="reservation"
                style={{ color: "#fff" }}
              >
                {t("header.reservation")}
              </Link>
            </li>
            <li className="header__navbar-item header-reserve">
              <Link
                className="header__navbar-link header-reserve"
                to="/Cambodia/Cambodia"
                style={{ color: "#fff" }}
              >
                {t("header.cambodia")}
              </Link>
            </li>
            <li className="header__navbar-item header-reserve">
              <Link
                to = "/Login"
                target="_blank"
                className="header__navbar-link header-reserve"
                style={{ color: "#fff" }}
              >
                Sign In
              </Link>
            </li>
            <li className="mt-2">
              <Link
                to = "/Login"
                target="_blank"
                style={{ color: "#fff" }}
              >
                <Avatar 
                src="https://scontent.fhan18-1.fna.fbcdn.net/v/t39.30808-1/394314988_3576024049338244_1513351123669816224_n.jpg?stp=dst-jpg_p200x200&_nc_cat=105&ccb=1-7&_nc_sid=5740b7&_nc_ohc=HHV1lmBFfe0AX8CBVCX&_nc_ht=scontent.fhan18-1.fna&oh=00_AfA3H6HQhxq3XDbbqahRGqQvW2LJ0LiHbLXO2oD5d1rWBA&oe=65A6ACCA"
                facebookId="FouNdeRLH"
                size="40"
                round={true}
                 />
              </Link>
            </li>
          </ul> */}
        </div>
        <div className="header__mobile-logo">
          <Link to="/">
            <img
               src="/webp/azlogo.webp"
              alt="logo"
            />
          </Link>
        </div>
        <div className="btn__header-mobile">
          <button
           onClick={toggleHeader}
           className="header__mobile">
            <i className="fa-solid fa-bars"></i>
          </button>
        </div>
        <HeaderMobile />
      </div>
      </div>
     <div className="is-sticky">
     <div className="container">
      <div className="header" style={{backgroundColor: backgroundColor}}>
        <Tabs
        // selectedTabClassName="service__active" 
        className="header__PC">
             <TabList className="header__navbar-list mb-0 justify-content-start">
             <Tab className="header__navbar-item">
             <div className="header__logo">
            <div className="container">
              <div className="row">
                <div className="col-md-12">
                  <Link to={`/${language}/`}>
                    <img
                       src="/webp/azlogo.webp"
                      alt="logo"
                    />
                  </Link>
                </div>
              </div>
            </div>
          </div>
                </Tab>
              </TabList>      
                  <TabList className="header__navbar-list mb-0">
                  <Tab className="header__navbar-item">
                      <Link className="header__navbar-link-2" to={`/${language}/`}>
                        {t("header.home")}
                      </Link>
                    </Tab>
                    <Tab className="header__navbar-item">
                      <Link className="header__navbar-link-2" to={`/${language}/news/`}>
                        {t("header.news")}
                      </Link>
                    </Tab>
                    <Tab className="header__navbar-item">
                      <Link className="header__navbar-link-2" to={`/${language}/hotel-hcm/`}>
                        {t("header.hcm")}
                      </Link>
                      <ul className="header__navbar-item-list" style={{width:200}}>
                        {hcm.map((item)=>(
                          <li className = "header__navbar-sub-link" >
                          <Link 
                          to ={item.link}>{item.name}
                          </Link>
                            </li>
                        ))}
                        </ul>
                    </Tab>
                    <Tab className="header__navbar-item">
                      <Link className="header__navbar-link-2" to={`/${language}/hotel-hn/`}>
                        {t("header.hn")}
                      </Link>
                      <ul className="header__navbar-item-list" style={{width:220}}>
                        {hn.map((item)=>(
                          <li className = "header__navbar-sub-link" >
                          <Link
                          to ={`/${language}${item.link}`}>{item.name}
                          </Link>
                            </li>
                        ))}
                        </ul>
                    </Tab>
                    <Tab className="header__navbar-item">
                      <Link className="header__navbar-link-2" to={`/${language}/hotel-dn/`}>
                        {t("header.dn")}
                      </Link>
                      <ul className="header__navbar-item-list" style={{width:200}}>
                        {dn.map((item)=>(
                          <li className = "header__navbar-sub-link" >
                          <Link 
                          to ={`/${language}${item.link}`}>{item.name}
                          </Link>
                            </li>
                        ))}
                        </ul>
                    </Tab>
                    <Tab className="header__navbar-item">
                      <Link className="header__navbar-link-2" to={`/${language}/hotel-hp/`}>
                        {t("header.hp")}
                      </Link>
                      <ul className="header__navbar-item-list" style={{width:200}}>
                        {hp.map((item)=>(
                          <li className = "header__navbar-sub-link" >
                          <Link 
                          to ={`/${language}${item.link}`}>{item.name}
                          </Link>
                            </li>
                        ))}
                        </ul>
                    </Tab>
                    <Tab className="header__navbar-item">
                      <Link className="header__navbar-link-2" style={{width:130}} to="http://azumayacambodia.com/">
                        {t("header.cambodia")}
                      </Link>
                    </Tab>
                    {/* <Tab className="header__navbar-item">
                            <Link  className = "header__navbar-link-2" to = '/Reservation'>{t('header.item3')}</Link></Tab> */}
                    <Tab className="header__navbar-item">
                      <Link className="header__navbar-link-2" to={`/${language}/feature/`}>
                        {t("header.feature")}
                      </Link>
                      <ul className="header__navbar-item-list">
                        {feature.map((item)=>(
                          <Tab className = "header__navbar-sub-link">
                          <Link 
                          to ={`/${language}/feature/${item.id}`}> 
                          <span style={{backgroundColor:"#482979", padding:"5px", marginRight:"8px"}}>
                            {item.num}</span>{t(item.title)}
                            </Link>
                            </Tab>
                        ))}
                        </ul>
                    </Tab>
                    <Tab className="header__navbar-item">
                      <Link className="header__navbar-link-2" to={`/${language}/service/`}>
                        {t("header.service")}
                      </Link>
                      <ul className="header__navbar-item-list" style={{width: 240}}>
                        {service.map((item)=>(
                          <li className = "header__navbar-sub-link" >
                          <Link 
                          to = {`/${language}${item.link}`}>{item.name}
                          </Link>
                            </li>
                        ))}
                        </ul>
                    </Tab>
                    <Tab className="header__navbar-item">
                      <Link className="header__navbar-link-2" to={`/${language}/contract/`}>
                        {t("header.contract")}
                      </Link>
                    </Tab>
                    <Tab className="header__navbar-item">
                      <Link className="header__navbar-link-2" to={`/${language}/reservation/`}>
                        {t("header.reservation")}
                      </Link>
                    </Tab>
                    <Tab className="header__navbar-item">
                      <Link className="header__navbar-link-2" to={`${language}/q&a/`}>
                        {t("header.policies")}
                      </Link>
                    </Tab>
                  </TabList>
                  </Tabs>
            </div>
          </div>
     </div>
    </>
  );
}
export default Header;
