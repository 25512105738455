import React, { useEffect, useState, useContext } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import axios from 'axios';
import { UserContext } from './userProvider';
import { useTranslation } from 'react-i18next';
 
const LineCallback = () => {
  const {t, i18n} = useTranslation()
  const language = i18n.language || 'en'
  const [profile, setProfile] = useState('');
  const [email, setEmail] = useState('')
  const [error, setError] = useState(null);
  const navigate = useNavigate();
  const location = useLocation();
  const { saveUserData, saveUserImage } = useContext(UserContext);
  const [token, setToken] = useState('')
  const CHANNEL_ID = '2005726085';
  const CHANNEL_SECRET = 'a0860b329dd55b86c9e72b3b356a52ca'
  const REDIRECT_URI = 'http://localhost:3000/en/callback';

  const postData = async (user) => {
    try {
      const response = await fetch(`http://localhost:3000/api/user/Line/f402a287-09ae-4e28-996e-091172956718`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(user), 
      });

      if (!response.ok) {
        throw new Error('Network response was not ok');
      }

      const responseData = await response.json();
      console.log('Response:', responseData);
      
      navigate(`/${language}/greeting`, {state: {responseData: responseData}})


    } catch (error) {
      console.error('Error:', error);
    }
  };

  useEffect(() => {
    const fetchToken = async (code) => {
      try {

        const tokenData = new URLSearchParams({
          grant_type: 'authorization_code',
          code: code,
          redirect_uri: REDIRECT_URI,
          client_id: CHANNEL_ID,
          client_secret: CHANNEL_SECRET,
        });

        const response = await axios.post(
          'https://api.line.me/oauth2/v2.1/token',
          tokenData.toString(),
          {
            headers: {
              'Content-Type': 'application/x-www-form-urlencoded',
            },
          }
        );

        setToken(response.data.access_token)
        const { access_token } = response.data;

        if (!access_token) {
          throw new Error('No access token received');
        }

        const profileResponse = await axios.get('https://api.line.me/v2/profile', {
          headers: {
            Authorization: `Bearer ${access_token}`,
          },
        });
       
        setProfile(profileResponse.data);
        saveUserData(profileResponse.data);
        saveUserImage(profileResponse.data.pictureUrl)
        const user = profileResponse.data
        postData(user)
        // navigate('/')
      } catch (error) {
        setError('Failed to fetch profile data');
      }
    };

    const params = new URLSearchParams(location.search);
    const code = params.get('code');
    if (code) {
      fetchToken(code);
    } else {
      setError('No code found in URL');
    }
  }, [location.search, navigate, saveUserData]);


  return (
    <div>
      {/* <h2>Login Successful</h2> */}
    </div>
  );
};

export default LineCallback;
