import { useState, useEffect } from "react"
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import { ToastContainer, toast, Flip } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export default function ResetPassword() {
    const [password, setPassword] = useState('')
    const [confirmPassword, setConfirmPassword] = useState('')
    const [errorMessage, setErrorMessage] = useState('');
    const [token, setToken] = useState('')
    // const [responseMsg, setResponseMsg] = useState('');
    const [responseStatus, setResponseStatus] = useState('');
    const url = useParams()
    const navigate = useNavigate()
    const {i18n} = useTranslation()
    const language = i18n.language

    useEffect(() => {
        const tokenEndIndex = url.token.indexOf('&source=');
        const extractedToken = tokenEndIndex !== -1 ? url.token.substring(0, tokenEndIndex) : url.token;
        setToken(extractedToken);
    }, []);

    const handleInputPassword = (e) => {
      const value = e.target.value
      setPassword(value)
      
    } 
    const handleConfirmPasswordChange = (e) => {
		const value = e.target.value;
		setConfirmPassword(value);
	
		if (password !== value) {
		  setErrorMessage('Password do not match');
		} else {
		  setErrorMessage('');
		}
	  };
    const validate = () => {
      let isValid = true;
      if (password === '') {
        // setErrorMessage('Password field must not be empty');
        toast.error('Password field must not be empty');
        isValid = false;
      } 
      else if (password.length < 8) {
        // setErrorMessage('Password field must be at least 8 characters')
        toast.error('Password field must be at least 8 characters')
        isValid = false
      }
      return isValid;
    };
  
    const handleConfirm = (e) => {
      e.preventDefault();
      if (validate()) {
        postData({ password: password });
      }
    };
  
    const postData = async (data) => {
        try {
          const response = await fetch(`http://localhost:3000/api/user/c4084eea-7034-41c5-9e33-75136a154266/${token}`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify(data)

          });
    
          if (!response.ok) {
            throw new Error('Network response was not ok');
          }
          const responseData = await response.json();
          if(responseData.status == 0) {
            toast.error(responseData.msgLang[language])
          }else{
            toast.success(responseData.msgLang[language])
            navigate(`/${language}/login`)
          }
        } catch (error) {
          console.error('Error:', error);
        }
      };
    return(
        <div className="d-flex flex-column justify-content-center align-items-center"style={{ height: '100vh', border: '1px solid #482979d'}}>
           <div className="login_header">
           <div className="login_title animation a0">
                <img src="https://azumayavietnam.com/image/logo/logo_az_k_bg.png" style={{width: 330, height: 140}}> 
                </img>
                </div>
            <h4 className=" mt-3 animation a1">Input your new password</h4>
           </div>
              <form className="login_card-form">
                <div className="login_input animation a4">
               <input
               type="password" className="login_input-field" 
               style={{width: 330}}
               value={password}
               onChange={handleInputPassword}
               />
              <label className="login_input-label ml-0">Password</label>
              </div>
              <div className="login_input animation a5">
              <input 
              type="password" 
              className="login_input-field"
              value={confirmPassword}
              onChange={handleConfirmPasswordChange}
              />
             <label className="login_input-label ml-0">Confirm Password</label>
              </div>
             {errorMessage && <div style={{ color: 'red' }}>{errorMessage}</div>}
                  <div className="mt-3 animation a6">
                  <button className="action-button" onClick={handleConfirm}>Confirm</button>
                  </div>
              </form>
              <ToastContainer 
               position="top-left"
              transition={Flip}
              />
              <div>
              </div>
              </div>
              )
    }