import { useParams } from "react-router-dom"
import { UserContext } from "../../userProvider";
import { useContext, useEffect, useState } from "react";    
import loadingGif from '../../loading2.gif'
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
export default function Active() {
    const [userData, setUserData] = useState('');
    const [serverData, setServerData] = useState('')
    const [count, setCount] = useState(3); 
    const navigate = useNavigate()
    const {i18n} = useTranslation()
    const language = i18n.language || 'en'
    const url = useParams()
    const token = url.token
    const getUserData = () => {
        const savedUserData = localStorage.getItem('userData');
        if (savedUserData) {
          setUserData(JSON.parse(savedUserData));
        }
      };
      
    const dataPost = {
        email: userData.email,
        token: token
    }
    const activeAcc = async (data) => {
          try {
            const response = await fetch(`http://localhost:3000/api/user/37eeb81e-a1e5-4774-a9b7-b15595e1dd4f/${token}`, {
              method: 'POST',
              headers: {
                'Content-Type': 'application/json',
              },
              body: JSON.stringify(data), 
            });
            if (!response.ok) {
              throw new Error('Network response was not ok');
            }
            const responseData = await response.json();
            console.log('Response:', responseData);
            setServerData(responseData)
          } catch (error) {
            console.error('Error:', error);
          }
        };
    useEffect(() => {
        getUserData('userData')
        activeAcc(dataPost)
    },[])

    useEffect(() => {
        const timer = setInterval(() => {
          setCount(prevCount => {
            if (prevCount === 1) {
              navigate(`/${language}/login`);
              return 0;
            } else {
              return prevCount - 1;
            }
          });
        }, 1000); // Decrement every second
    
        return () => clearInterval(timer); // Cleanup the interval on component unmount
      }, [navigate]);
    return(
    <div className="d-flex justify-content-center align-items-center active-container">
        {serverData.status == 1 ? 
        <div>
        <i class="fa-solid fa-user-check mb-3" style={{color: '#482979', fontSize: '5rem'}}></i>
        <h1>Your account has been successfully activated!</h1>
        <p>Thank you for confirming your account</p>
        <p style={{fontSize: '1.4rem', marginTop: 30, display:"flex", alignItems:'center'}}>You will be redirect to Login page in {count} second <img src={loadingGif} style={{marginLeft:5,width: 20, height: 20}}/></p> 
        </div>
            : 
        <div className="d-flex flex-column align-items-center">
        <i class="fa-solid fa-user-check mb-3" style={{color: '#482979', fontSize: '5rem'}}></i>
        <h1>Your account has already been activated! Please log in </h1>
        <p style={{fontSize: '1.4rem', marginTop: 30, display:"flex", alignItems:'center'}}>You will be redirect to Login page in {count} second <img src={loadingGif} style={{marginLeft:5,width: 20, height: 20}}/></p> 
        </div>
        }
    </div>
    )
}
