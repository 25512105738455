import { Link, useLocation, useNavigate} from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useState, useEffect, useContext } from "react";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs"
import { UserContext } from "../../userProvider";

import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import profilePicture from '../../image/profile-picture.png'
import azcoin from '../../image/azcoin-des2.png'
import bronze from '../../image/bronze-3.png'
import silver from '../../image/Silver-3.png'
import gold from '../../image/Gold-3.png'
import diamond from '../../image/Diamond-4.png'
function InputGuestInfo(props) {
  const [familyName, setFamilyName] = useState('');
  const [givenName, setGivenName] = useState('');
  const [gender, setGender] = useState('')
  const [day, setDay] = useState({});
  const [month, setMonth] = useState('');
  const [year, setYear] = useState('');
  const [email, setEmail] = useState('');
  const [specialRequest, setSpecialRequest] = useState('');
  const [smoking, setSmoking] = useState('');
  const [errors, setErrors] = useState({})
  const [imagePreviewUrl, setImagePreviewUrl] = useState(null);
  const [passport, setPassport] = useState('')
  const [vat, setVat] = useState('')
  const [vatNote, setVatNote] = useState('')

  const handleImageChange = (e) => {
    e.preventDefault();

    const file = e.target.files[0];
    if (!file) return;

    const reader = new FileReader();
    reader.onloadend = () => {
      setImagePreviewUrl(reader.result);
    };
    reader.readAsDataURL(file);
  };
  
  const {t} = useTranslation()

  function DayPicker() {
		const minDay = 1;
		const maxDay = 31;
		const birthDay = 0;
		const options = [];
		for (let i = minDay; i <= maxDay; i++) {
		  const day = birthDay + i;
		  options.push(
			<option value={day} key={day}>
			  {day <=9 ? '0' + day : day}
			</option>
		  );
		}
		return (
		  <select
			className={ errors.day ? "form__content validate_failed" : "form__content mb-0 mr-0"}
				style={{fontSize: '1.2rem'}}
				value={day}
				onChange={(e) => {
					setDay(e.target.value)
					errors.day = ''
			}}
		  >
			<option className="first-opt">
			  {t("reservation.day")}
			</option>
			{options}
		  </select>
		);
	  }
	function MonthPicker() {
		const minMonth = 0;
		const maxMonth = 11;
		const birthMonth = 1;
	
		const options = [];
		for (let i = minMonth; i <= maxMonth; i++) {
		  const month = birthMonth + i;
		  options.push(
			<option value={month} key={month}>
			  {month <=9 ? '0' + month : month}
			</option>
		  );
		}
		return (
		  <select
			className={ errors.month ? "form__content validate_failed" : "form__content mb-0 mr-0"}
				style={{fontSize: '1.2rem'}}
				value={month}
				onChange={(e) => { 
					setMonth(e.target.value)
					errors.month = ''
				}}
		  >
			<option className="first-opt">
			  {t("reservation.month")}
			</option>
			{options}
		  </select>
		);
	  }
	function YearPicker() {
		const minYear = 1925;
		const maxYear = 2005;
		const birthYear = 0;
	
		const options = [];
		for (let i = minYear; i <= maxYear; i++) {
		  const year = birthYear + i;
		  options.push(
			<option value={year} key={year}>
			  {year}
			</option>
		  );
		}
		return (
		  <select
			className={ errors.year ? "form__content validate_failed" : "form__content mb-0 mr-0"}
				style={{fontSize: '1.2rem'}}
				value={year}
				onChange={(e) => {
					setYear(e.target.value)
					errors.year = ''
				}}
		  >
			<option className="first-opt">
			  {t("reservation.year")}
			</option>
			{options}
		  </select>
		);
	  }

  const validateEmail = (email) => {
    // Regular expression for email validation
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };
  const validate = () => {
    let errors = {}
    let isVaLid = true
  
    if (!familyName) {
      errors.familyName = 'required';
      isVaLid = false
    }
    if (!givenName) {
      errors.givenName = 'required';
      isVaLid = false
    } 
    if (!gender) {
      errors.gender = 'required';
      isVaLid = false
    } 
    if (!email) {
      errors.email = 'required';
      isVaLid = false
  
    } 
    else if (!validateEmail(email)) {
      errors.email = 'Invalid email format';
      isVaLid = false
  
    }
    setErrors(errors);
    return isVaLid
  }

  const handleSubmit = async(e) => {
    const dataObject = {
      familyName,
      givenName,
      gender,
      phone,
      email,
      specialRequest,
      language,
      deviceType
    }
    // console.log(dataObject);
    e.preventDefault()
    if(validate()) {
      const token = "73344833-5b52-4403-9255-695907647688"
    const source = await sendMassageRequest(dataObject, token)
    navigate (`/${language}/massage/thank-you/${city}`)
    }else{
      alert(`Please ensure that all required fields are completed`)
    }
  }
  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      backdrop="static"
      keyboard={false}
    >
      <Modal.Header>
        <Modal.Title id="contained-modal-title-vcenter">
          {t("login.add-guest")}
        </Modal.Title>
        <Button variant="light" onClick={props.onHide}>
          <i class="fa-solid fa-xmark purple"></i>
        </Button>
      </Modal.Header>
      <div className="row p-5 pb-0">
        <div className="col-md-12 massage_reservation" style={{borderRight: 'none'}}>
          <Modal.Body>
        <form onSubmit={handleSubmit}>
            <h2>{t("login.guest-info")}</h2>
            <div className="row pl-3 pr-3">
              <input
                placeholder={t("service_massage.guest_name")}
                type="text"
                className={errors.familyName ? 'col-md-12 form__content mb-0 validate_failed' : 'col-md-12 form__content mb-0'}
                value={familyName}
                onChange={(e) => {
                  setFamilyName(e.target.value);
                  errors.familyName = ''
                }}
              />
            </div>
            <div className="row pl-3 pr-3">
            <div style={{color: '#8597a3', fontSize: '1.2rem'}}>{t("reservation.gender")}</div>
            <div className="col-md-4">
            <input
                 type="radio"
                 name="gender"
                 id="gMale"
                 value="Mr."
                 checked={gender === "Mr."}
                 onChange={(e) => setGender(e.target.value)}
               />
               <label htmlFor="gMale">{t("reservation.mr")}</label>
                 </div>
            <div className="col-md-4">
               <input
                 type="radio"
                 name="gender"
                 id="gFemale"
                 value="Ms."
                 checked = {gender === "Ms."}
                 onChange={(e) => setGender(e.target.value)}
               />
               <label htmlFor="gFemale">{t("reservation.ms")}</label>
            </div>
            </div>
              <div className="row pt-0">
                <div className="login_input">
                <div style={{fontSize: '1.2rem',color: !day || !month || !year ? '#8597a3' : '#482979', textAlign: 'left'}}>{t("reservation.birth-date")}</div>
                </div>
                  <div className="col-md-4 pl-0">
                  <div className="login_input">
                    <DayPicker />
                  </div>
                  </div>
                  <div className="col-md-4">
                  <div className="login_input">
                    <MonthPicker />

                  </div>
                  </div>
                  <div className="col-md-4 pr-0">
                  <div className="login_input">
                    <YearPicker />
                  </div>
                  </div>
              </div>
            <div className="row pl-3 pr-3">
                  <input
                type="text"
                className={errors.email ? 'col-md-12 form__content validate_failed' : 'col-md-12 form__content'}
                id=""
                value={email}
                placeholder={t("service_massage.email")}
                onChange={(e) => {
                  setEmail(e.target.value)
                  errors.email = ''
                }}
              />
               <div style={{color: '#8597a3', fontSize: '1.2rem'}}>{t("room_hbt1.type")}</div>
            <div className="col-md-4">
                 <input
                   type="radio"
                   name="roomType"
                   id="smk"
                   value="Smoking"                       
                   onClick={(e) => setSmoking(e.target.value)}
                 />
                 <label htmlFor="smk">{t("reservation.smk")}</label>
               </div>
               <div className="col-md-4">
                 <input
                   type="radio"
                   name="roomType"
                   id="no-smk"
                   value="Non Smoking"
                   onClick={(e) => setSmoking(e.target.value)}
                 />
                 <label htmlFor="no-smk">{t("reservation.non-smk")}</label>
                </div>
                <div style={{color: '#8597a3', fontSize: '1.2rem'}}>{t("login.passport-image")}</div>
              <div className="col-md-4">
                 <input type="file" onChange={handleImageChange} />
                 {imagePreviewUrl && (
                      <img className="mt-3" src={imagePreviewUrl} alt="Image Preview" style={{ maxWidth: '100%', height: 'auto' }} />
                  )}
               </div>
               <input
                type="text"
                className={errors.email ? 'col-md-12 form__content validate_failed mt-4' : 'col-md-12 form__content mt-4'}
                style={{textTransform: 'uppercase'}}
                value={passport}
                placeholder={t("login.passport")}
                onChange={(e) => {
                  setPassport(e.target.value)
                }}
              />
              <div style={{color: '#8597a3', fontSize: '1.2rem'}}>VAT: </div>
               <div className="col-md-4">
                 <input
                   type="radio"
                   name="vatInvoice"
                   id="no-need"
                   className="VATInvoice"
                   value="No Necessary"
                   onClick={(e)=> setVat(e.target.value)}
                 />
                 <label htmlFor="no-need">{t("reservation.n-need")}</label>
               </div>
               <div className="col-md-4">
                 <input
                   type="radio"
                   name="vatInvoice"
                   id="need"
                   className="VATInvoice"
                   value="Necessary"
                   onClick={(e)=> setVat(e.target.value)}
                 />
                 <label htmlFor="need">{t("reservation.need")}</label>
               </div>
               <textarea
                className="text-note"
                cols="40"
                rows="2"
                placeholder={`VAT ${t("service_massage.special")}`}
                value={vatNote}
                onChange={(e) => setVatNote(e.target.value)}></textarea>
              <textarea
                className="text-note mt-5"
                cols="40"
                rows="4"
                placeholder={t("service_massage.special")}
                value={specialRequest}
                onChange={(e) => setSpecialRequest(e.target.value)}></textarea>
              <div className="row justify-content-center">
                <button
                  id="send"
                  class="button-57 send-btn col-2"
                  type="submit"
                  style={{backgroundColor:"#482979", border: '1px solid #482979'}}
                >
                  <span class="text" style={{ color: "#fff" }}>
                    Save
                  </span>
                  <span className="d-flex align-item-center">
                    <i
                      class="fa-regular fa-floppy-disk"
                      style={{ fontSize: "2.8rem", lineHeight: "2.8rem", color: "#482979" }}
                    ></i>
                  </span>
                </button>
              </div>
            </div>
            
            </form>
          </Modal.Body>
        </div>
      </div>
      <Modal.Footer>
        <button
          class="button-57 close-btn"
          role="button"
          onClick={props.onHide}
        >
          <span class="text" style={{ color: "#fff" }}>
            {t('service_massage.close')}
          </span>
          <span>
            <i
              class="fa-solid fa-xmark red"
              style={{ fontSize: "1.8rem", lineHeight: "2.8rem" }}
            ></i>
          </span>
        </button>
      </Modal.Footer>
    </Modal>
  );
}



export default function Setting({userData}) {
  const { logout } = useContext(UserContext);
  const [familyName, setFamilyName] = useState('')
	const [givenName, setGivenName] = useState('')
	const [password, setPassword] = useState('')
	const [confirmPassword, setConfirmPassword] = useState('')
	const [errors, setErrors] = useState({});
  const { t, i18n } = useTranslation();
  const language = i18n.language || 'en'
  const location = useLocation();
  const navigate = useNavigate();
  const genderValue = t("gender", { returnObjects: true });
  const [userImage, setUserImage] = useState('')
  const [userName, setUserName] = useState('')
  const [userEmail, setUserEmail] = useState('')
  const [phone, setPhone] = useState('')
  const [birthDate, setBirthDate] = useState('')
  const [day, setDay] = useState('')
	const [month, setMonth] = useState('')
	const [year, setYear] = useState('')
	const [gender, setGender] = useState('')
  const [voucher, setVoucher] = useState([])
  const [listGuest, setListGuest] = useState([])
  const [coin, setCoin] = useState()
  const [rank, setRank] = useState()
  const [_id, set_ID] = useState('')

  const [otherDay, setOtherDay] = useState('')
	const [otherMonth, setOtherMonth] = useState('')
	const [otherYear, setOtherYear] = useState('')
  const [navigateOnUpdate, setNavigateOnUpdate] = useState(false);

  // const [userDataSocial, setUserDataSocial] = useState()
  // const userDataSocial = JSON.parse(localStorage.getItem('userData'))
  const handleLogout = () => {
    logout();
    navigate(`/${language}/`);
    window.location.reload();
  };

const splitDate = (dateString) => {
  const [daySplit, monthSplit, yearSplit] = dateString.split('-')
  setDay(daySplit)
  setMonth(monthSplit)
  setYear(yearSplit)
}

const splitDateOther = (dateString) => {
  const [daySplit, monthSplit, yearSplit] = dateString.split('-')
  setOtherDay(daySplit)
  setOtherMonth(monthSplit)
  setOtherYear(yearSplit)
}

const formatDate = (dateString) => {
  const date = new Date(dateString);

  const formatter = new Intl.DateTimeFormat('en-US', {
    year: 'numeric',
    month: 'short',
    day: '2-digit'
  });

  return formatter.format(date);
};

const remainDate = (dateString) => {
  const futureDate = new Date(dateString);
  const today = new Date();

  const differenceInMs = futureDate - today;
  const differenceInDays = Math.ceil(differenceInMs / (1000 * 60 * 60 * 24));
  // const differenceInHours = Math.ceil(differenceInMs / (1000 * 60 * 60));
  // const differenceInMinutes = Math.ceil(differenceInMs / (1000 * 60));

  return differenceInDays
    // hours: differenceInHours,
    // minutes: differenceInMinutes,

};

  const [modalShow, setModalShow] = useState(false);
  const [modalShow1, setModalShow1] = useState(false);
  const [modalShow2, setModalShow2] = useState(false);
  const [index, setIndex] = useState(0)

  useEffect(() => {
    if (userData) {
      const image = userData.data.avatar || profilePicture ;
      const name = userData.data.name  ||  ''
      setUserImage(image);
      setUserName(name);
      setFamilyName(userData.data.familyName || '')
      setGivenName(userData.data.givenName || '')
      setUserEmail(userData.data.email || '')
      setPhone(userData.data.phone || '')
      setBirthDate(userData.data.birthDate  || '')
      splitDate(userData.data.birthDate || '')
      setGender(userData.data.gender || '')
      setVoucher(userData.data.voucher || [])
      setListGuest(userData.data.listGuest || [])
      setCoin(userData.data.azCoin || '')
      setRank(userData.data.rank || '')
      set_ID(userData.data._id || '')
    }
  }, [userData]);

  useEffect(() => {
    if (voucher.length > 0) {
      setVoucher(voucher)
    }
  }, [voucher]);

  useEffect(() => {
    if (listGuest.length > 0) {
      setListGuest(listGuest)
    }
  }, [listGuest]);
  useEffect(() => {
    if (navigateOnUpdate && otherDay !== '' && otherMonth !== '' && otherYear !== '') {
      navigate(`/${language}/reservation/`, {
        state: {
          familyName: userData.data.listGuest[index].name || '',
          givenName: userData.data.listGuest[index].givenName || '',
          email: userData.data.listGuest[index].email || '',
          birthDay: otherDay,
          birthMonth: otherMonth,
          birthYear: otherYear,
          gender: userData.data.listGuest[index].gender || '',
          smoking: userData.data.listGuest[index].smoking || '',
          vat: userData.data.listGuest[index].vat || ''
        }
      });
      setNavigateOnUpdate(false); // Reset navigation trigger
    }
  }, [navigateOnUpdate, otherDay, otherMonth, otherYear, navigate, language, userData, index]);

  function DayPicker() {
		const minDay = 1;
		const maxDay = 31;
		const birthDay = 0;
		const options = [];
		for (let i = minDay; i <= maxDay; i++) {
		  const day = birthDay + i;
		  options.push(
			<option value={day} key={day}>
			  {day <=9 ? '0' + day : day}
			</option>
		  );
		}
		return (
		  <select
			className={ errors.day ? "form__content validate_failed" : "form__content mb-0 mr-0"}
				style={{fontSize: '1.2rem'}}
				value={day}
				onChange={(e) => {
					setDay(e.target.value)
					errors.day = ''
			}}
		  >
			<option className="first-opt">
			  {t("reservation.day")}
			</option>
			{options}
		  </select>
		);
	  }
	function MonthPicker() {
		const minMonth = 0;
		const maxMonth = 11;
		const birthMonth = 1;
	
		const options = [];
		for (let i = minMonth; i <= maxMonth; i++) {
		  const month = birthMonth + i;
		  options.push(
			<option value={month} key={month}>
			  {month <=9 ? '0' + month : month}
			</option>
		  );
		}
		return (
		  <select
			className={ errors.month ? "form__content validate_failed" : "form__content mb-0 mr-0"}
				style={{fontSize: '1.2rem'}}
				value={month}
				onChange={(e) => { 
					setMonth(e.target.value)
					errors.month = ''
				}}
		  >
			<option className="first-opt">
			  {t("reservation.month")}
			</option>
			{options}
		  </select>
		);
	  }
	function YearPicker() {
		const minYear = 1925;
		const maxYear = 2005;
		const birthYear = 0;
	
		const options = [];
		for (let i = minYear; i <= maxYear; i++) {
		  const year = birthYear + i;
		  options.push(
			<option value={year} key={year}>
			  {year}
			</option>
		  );
		}
		return (
		  <select
			className={ errors.year ? "form__content validate_failed" : "form__content mb-0 mr-0"}
				style={{fontSize: '1.2rem'}}
				value={year}
				onChange={(e) => {
					setYear(e.target.value)
					errors.year = ''
				}}
		  >
			<option className="first-opt">
			  {t("reservation.year")}
			</option>
			{options}
		  </select>
		);
	  }
  function EditGuestInfo(props) {
      const [familyName, setFamilyName] = useState('');
      const [gender, setGender] = useState('')
      const [day, setDay] = useState('');
      const [month, setMonth] = useState('');
      const [year, setYear] = useState('');
      const [email, setEmail] = useState('');
      const [specialRequest, setSpecialRequest] = useState('');
      const [smoking, setSmoking] = useState('');
      const [errors, setErrors] = useState({})
      const [imagePreviewUrl, setImagePreviewUrl] = useState(null);
      const [passport, setPassport] = useState('')
      const [vat, setVat] = useState('')
      const [vatNote, setVatNote] = useState('')
      
      const splitDate = (dateString) => {
        const [daySplit, monthSplit, yearSplit] = dateString.split('-')
        setDay(daySplit)
        setMonth(monthSplit)
        setYear(yearSplit)
      }
      useEffect(() => {
        if (userData && userData.data && userData.data.listGuest && userData.data.listGuest[index]) {
          const guest = userData.data.listGuest[index];
          splitDate(guest.birthDate);
          setFamilyName(guest.name);
          setGender(guest.gender);
          setEmail(guest.email);
          setSmoking(guest.smoking);
          setVat(guest.vat);
        }
      }, [userData, index]);
      const handleImageChange = (e) => {
        e.preventDefault();
    
        const file = e.target.files[0];
        if (!file) return;
    
        const reader = new FileReader();
        reader.onloadend = () => {
          setImagePreviewUrl(reader.result);
        };
        reader.readAsDataURL(file);
      };
      
      const {t} = useTranslation()
    
      function DayPicker() {
        const minDay = 1;
        const maxDay = 31;
        const birthDay = 0;
        const options = [];
        for (let i = minDay; i <= maxDay; i++) {
          const day = birthDay + i;
          options.push(
          <option value={day} key={day}>
            {day <=9 ? '0' + day : day}
          </option>
          );
        }
        return (
          <select
          className={ errors.day ? "form__content validate_failed" : "form__content mb-0 mr-0"}
            style={{fontSize: '1.2rem'}}
            value={day}
            onChange={(e) => {
              setDay(e.target.value)
              errors.day = ''
          }}
          >
          <option className="first-opt">
            {t("reservation.day")}
          </option>
          {options}
          </select>
        );
        }
      function MonthPicker() {
        const minMonth = 0;
        const maxMonth = 11;
        const birthMonth = 1;
      
        const options = [];
        for (let i = minMonth; i <= maxMonth; i++) {
          const month = birthMonth + i;
          options.push(
          <option value={month} key={month}>
            {month <=9 ? '0' + month : month}
          </option>
          );
        }
        return (
          <select
          className={ errors.month ? "form__content validate_failed" : "form__content mb-0 mr-0"}
            style={{fontSize: '1.2rem'}}
            value={month}
            onChange={(e) => { 
              setMonth(e.target.value)
              errors.month = ''
            }}
          >
          <option className="first-opt">
            {t("reservation.month")}
          </option>
          {options}
          </select>
        );
        }
      function YearPicker() {
        const minYear = 1925;
        const maxYear = 2005;
        const birthYear = 0;
      
        const options = [];
        for (let i = minYear; i <= maxYear; i++) {
          const year = birthYear + i;
          options.push(
          <option value={year} key={year}>
            {year}
          </option>
          );
        }
        return (
          <select
          className={ errors.year ? "form__content validate_failed" : "form__content mb-0 mr-0"}
            style={{fontSize: '1.2rem'}}
            value={year}
            onChange={(e) => {
              setYear(e.target.value)
              errors.year = ''
            }}
          >
          <option className="first-opt">
            {t("reservation.year")}
          </option>
          {options}
          </select>
        );
        }
    
      const validateEmail = (email) => {
        // Regular expression for email validation
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return emailRegex.test(email);
      };
      const validate = () => {
        let errors = {}
        let isVaLid = true
      
        if (!familyName) {
          errors.familyName = 'required';
          isVaLid = false
        }
        if (!givenName) {
          errors.givenName = 'required';
          isVaLid = false
        } 
        if (!gender) {
          errors.gender = 'required';
          isVaLid = false
        } 
        if (!email) {
          errors.email = 'required';
          isVaLid = false
      
        } 
        else if (!validateEmail(email)) {
          errors.email = 'Invalid email format';
          isVaLid = false
      
        }
        if (!phone) {
          errors.phone = 'required';
          isVaLid = false
        }
        setErrors(errors);
        return isVaLid
      }
    
      const handleSubmit = async(e) => {
        const dataObject = {
          familyName,
          givenName,
          gender,
          phone,
          email,
          specialRequest,
          language,
          deviceType
        }
        // console.log(dataObject);
        e.preventDefault()
        if(validate()) {
          const token = "73344833-5b52-4403-9255-695907647688"
        const source = await sendMassageRequest(dataObject, token)
        navigate (`/${language}/massage/thank-you/${city}`)
        }else{
          alert(`Please ensure that all required fields are completed`)
        }
      }
      return (
        <Modal
          {...props}
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered
          backdrop="static"
          keyboard={false}
        >
          <Modal.Header>
            <Modal.Title id="contained-modal-title-vcenter">
             {t("login.edit-guest")}
            </Modal.Title>
            <Button variant="light" onClick={props.onHide}>
              <i class="fa-solid fa-xmark purple"></i>
            </Button>
          </Modal.Header>
          <div className="row p-5 pb-0">
            <div className="col-md-12 massage_reservation" style={{borderRight: 'none'}}>
              <Modal.Body>
              <form onSubmit={handleSubmit}>
            <h2>{t("login.guest-info")}</h2>
            <div className="row pl-3 pr-3">
              <input
                placeholder={t("service_massage.guest_name")}
                type="text"
                className={errors.familyName ? 'col-md-12 form__content mb-0 validate_failed' : 'col-md-12 form__content mb-0'}
                value={familyName}
                onChange={(e) => {
                  setFamilyName(e.target.value);
                  errors.familyName = ''
                }}
              />
            </div>
            <div className="row pl-3 pr-3">
            <div style={{color: '#8597a3', fontSize: '1.2rem'}}>{t("reservation.gender")}</div>
            <div className="col-md-4">
            <input
                 type="radio"
                 name="gender"
                 id="gMale"
                 value="Mr."
                 checked={gender === "Mr."}
                 onChange={(e) => setGender(e.target.value)}
               />
               <label htmlFor="gMale">{t("reservation.mr")}</label>
                 </div>
            <div className="col-md-4">
               <input
                 type="radio"
                 name="gender"
                 id="gFemale"
                 value="Ms."
                 checked = {gender === "Ms."}
                 onChange={(e) => setGender(e.target.value)}
               />
               <label htmlFor="gFemale">{t("reservation.ms")}</label>
            </div>
            </div>
              <div className="row pt-0">
                <div className="login_input">
                <div style={{fontSize: '1.2rem',color: !day || !month || !year ? '#8597a3' : '#482979', textAlign: 'left'}}>{t("reservation.birth-date")}</div>
                </div>
                  <div className="col-md-4 pl-0">
                  <div className="login_input">
                    <DayPicker />
                  </div>
                  </div>
                  <div className="col-md-4">
                  <div className="login_input">
                    <MonthPicker />

                  </div>
                  </div>
                  <div className="col-md-4 pr-0">
                  <div className="login_input">
                    <YearPicker />
                  </div>
                  </div>
              </div>
            <div className="row pl-3 pr-3">
                  <input
                type="text"
                className={errors.email ? 'col-md-12 form__content validate_failed' : 'col-md-12 form__content'}
                id=""
                value={email}
                placeholder={t("service_massage.email")}
                onChange={(e) => {
                  setEmail(e.target.value)
                  errors.email = ''
                }}
              />
               <div style={{color: '#8597a3', fontSize: '1.2rem'}}>{t("room_hbt1.type")}</div>
            <div className="col-md-4">
                 <input
                   type="radio"
                   name="roomType"
                   id="smk"
                   value="Smoking"                       
                   onClick={(e) => setSmoking(e.target.value)}
                 />
                 <label htmlFor="smk">{t("reservation.smk")}</label>
               </div>
               <div className="col-md-4">
                 <input
                   type="radio"
                   name="roomType"
                   id="no-smk"
                   value="Non Smoking"
                   onClick={(e) => setSmoking(e.target.value)}
                 />
                 <label htmlFor="no-smk">{t("reservation.non-smk")}</label>
                </div>
                <div style={{color: '#8597a3', fontSize: '1.2rem'}}>{t("login.passport-image")}</div>
              <div className="col-md-4">
                 <input type="file" onChange={handleImageChange} />
                 {imagePreviewUrl && (
                      <img className="mt-3" src={imagePreviewUrl} alt="Image Preview" style={{ maxWidth: '100%', height: 'auto' }} />
                  )}
               </div>
               <input
                type="text"
                className={errors.email ? 'col-md-12 form__content validate_failed mt-4' : 'col-md-12 form__content mt-4'}
                style={{textTransform: 'uppercase'}}
                value={passport}
                placeholder={t("login.passport")}
                onChange={(e) => {
                  setPassport(e.target.value)
                }}
              />
              <div style={{color: '#8597a3', fontSize: '1.2rem'}}>VAT: </div>
               <div className="col-md-4">
                 <input
                   type="radio"
                   name="vatInvoice"
                   id="no-need"
                   className="VATInvoice"
                   value="No Necessary"
                   onClick={(e)=> setVat(e.target.value)}
                 />
                 <label htmlFor="no-need">{t("reservation.n-need")}</label>
               </div>
               <div className="col-md-4">
                 <input
                   type="radio"
                   name="vatInvoice"
                   id="need"
                   className="VATInvoice"
                   value="Necessary"
                   onClick={(e)=> setVat(e.target.value)}
                 />
                 <label htmlFor="need">{t("reservation.need")}</label>
               </div>
               <textarea
                className="text-note"
                cols="40"
                rows="2"
                placeholder={`VAT ${t("service_massage.special")}`}
                value={vatNote}
                onChange={(e) => setVatNote(e.target.value)}></textarea>
              <textarea
                className="text-note mt-5"
                cols="40"
                rows="4"
                placeholder={t("service_massage.special")}
                value={specialRequest}
                onChange={(e) => setSpecialRequest(e.target.value)}></textarea>
              <div className="row justify-content-center">
                <button
                  id="send"
                  class="button-57 send-btn col-2"
                  type="submit"
                  style={{backgroundColor:"#482979", border: '1px solid #482979'}}
                >
                  <span class="text" style={{ color: "#fff" }}>
                    Save
                  </span>
                  <span className="d-flex align-item-center">
                    <i
                      class="fa-regular fa-floppy-disk"
                      style={{ fontSize: "2.8rem", lineHeight: "2.8rem", color: "#482979" }}
                    ></i>
                  </span>
                </button>
              </div>
            </div>
            
            </form>
              </Modal.Body>
            </div>
          </div>
          <Modal.Footer>
            <button
              class="button-57 close-btn"
              role="button"
              onClick={props.onHide}
            >
              <span class="text" style={{ color: "#fff" }}>
                {t('service_massage.close')}
              </span>
              <span>
                <i
                  class="fa-solid fa-xmark red"
                  style={{ fontSize: "1.8rem", lineHeight: "2.8rem" }}
                ></i>
              </span>
            </button>
          </Modal.Footer>
        </Modal>
      );
    }
    const UserRank = () => {
      if(rank == "gold"){
        return <img src = {gold} className="rank-icon" />
      }else if(rank == "silver"){
        return <img src = {silver} className="rank-icon" />
      }else if(rank == "bronze"){
        return <img src = {bronze} className="rank-icon" />
      }else return <img src = {diamond} className="rank-icon" />
    }
    const handleClick = (index) => {
      splitDateOther(userData.data.listGuest[index].birthDate)
     setNavigateOnUpdate(true)
    }
    
    const deleteAcc = async (data) => {
      try {
        const response = await fetch('http://localhost:3000/api/user/26bed56b-5a1f-482f-b450-d43c7cebef54', {
          method: 'DELETE',
          headers: {
            'Content-Type': 'application/json',
          },
          body:JSON.stringify(data)
        });
  
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        const responseData = await response.json();
        if(responseData.status == 1){
          logout()
          navigate(`/${language}/`,{state: {msg: responseData.msg}})
          window.location.reload()
        }
      } catch (error) {
        console.error('Error:', error);
    }
  };
  const deleteData = {
    userId: _id
  }
  function ConfirmDelete(props) {
    return(
      <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      backdrop="static"
      keyboard={false}
    >
      <Modal.Header>
        <Modal.Title id="contained-modal-title-vcenter">
        Warning: Account Deletion
        </Modal.Title>
        <Button variant="light" onClick={props.onHide}>
          <i class="fa-solid fa-xmark purple"></i>
        </Button>
      </Modal.Header>
      <div className="row p-5 pb-0">
        <div className="col-md-12 massage_reservation" style={{borderRight: 'none'}}>
          <Modal.Body>
          <div style={{fontSize: '1.4rem'}}>
              <p>Deleting your account is a permanent action and cannot be undone. Once your account is deleted:</p>
              <ul>
                <li>+ All your data, including messages, contacts, and any other personal information, will be permanently erased.</li>
                <li>+ You will lose access to any services or subscriptions associated with this account.</li>
                <li>+ Any content you have shared or posted may no longer be accessible.</li>
              </ul>
              <p>Please ensure you have saved any important information before proceeding. If you are sure you want to delete your account, click "Delete Account." Otherwise, you can cancel this action and retain your account.</p>
              <p style={{textAlign: 'center', color:"#ff3131", fontWeight:'bold'}}>Are you sure you want to delete your account?</p>
            </div>
          </Modal.Body>
        </div>
      </div>
      <Modal.Footer>
      <button
          class="close-btn"
          role="button"
          onClick={()=> deleteAcc(deleteData)}
          style={{backgroundColor: "#65B741", border: '1px solid #65B741'}}
        >
          <span class="text" style={{ color: "#fff" }}>
            Confirm
          </span>
        </button>
        <button
          class="close-btn"
          role="button"
          onClick={props.onHide}
        >
          <span class="text" style={{ color: "#fff" }}>
            Cancel
          </span>
        </button>
      </Modal.Footer>
    </Modal>
    )
  }
    return(
        <>
             <div className="reservation__content" style={{padding: 15}}>
      <div className="container">
        <div className="row">
          <div className="col-md-12">
          </div>
        </div>
      </div>
    </div>
    <div className="container mt-5">
            <div className="row">
                <div className="col-md-12">
                <div className="d-flex align-items-end ">
                          <img className="profile-image" src={userImage} alt="Profile" style={{width: 130, height: 130, borderRadius: '50%'}}/>
                          <div className="d-flex align-items-center">
                          <p className="ml-3" style={{color: "#000", marginRight: 10, fontSize: '3rem'}}>{userName}</p>
                          <UserRank />
                          <img src = {gold} className="rank-icon" />
                          <img src = {silver} className="rank-icon" />
                          <img src = {bronze} className="rank-icon" />
                          </div>
                          </div>
                  <div className="d-flex align-items-center mt-3">
                    <img src={azcoin} style={{width: 30, height:30}} />
                    <div style={{color: "#fece07", fontWeight:'bold', fontSize: '1.8rem'}}>{Intl.NumberFormat('de-DE').format(coin)}</div>
                  </div>
                </div>
                <Tabs className="container">
        <TabPanel>
          <Tabs
            selectedTabClassName="service__active"
            className="col-md-12 p-0"
          >
            <TabList className="service__list mt-3">
                <Tab className="service">{t("login.info")}</Tab>
                <Tab className="service">{t("login.coupon")}</Tab>
                <Tab className="service">{t("login.book-other")}</Tab>
                <Tab className="service">{t("login.book-history")}</Tab>
                <Tab className="service">{t("login.logout")}</Tab>
            </TabList>
            <TabPanel>
              <div className="service__content">
                <div className="room__content">
                  <div className="room__title">
                   {t("login.user-info")}
                    <img
                      className="style-line"
                      src="https://azumayavietnam.com/image/logo/style-line.png"
                      alt=""
                    />
                    <div className="container pl-5 pr-5">
                    <div className="row pl-5 pr-5">
                    <div className="col-md-6">
                        <div className="login_input">
                        <input 
                        type="text" 
                        className="login_input-field" 
                        value={familyName}
                        onChange={(e) => setFamilyName(e.target.value)}
                        required/>
                        <label className="login_input-label ml-0">{t('reservation.family-name')}</label>
                    </div>
                        </div>
                    <div className="col-md-6">
                        <div className="login_input">
                        <input 
                        type="text" 
                        className="login_input-field" 
                        value={givenName}
                        onChange={(e) => setGivenName(e.target.value)}
                        required/>
                        <label className="login_input-label ml-0">{t('reservation.given-name')}</label>
                    </div>
                    </div>
			        </div>
            <div className="row pl-5 pr-5">
                <div className="col-md-6">
                    <div className="row">
				<div style={{fontSize: '1.2rem', color: !gender ? '#8597a3' : '#482979', textAlign: 'left', padding: 5}}>{t('reservation.gender')}</div>
                        <div className="login_input">
                </div>

				<select
                className={errors.gender ? "col-md-12 form__content validate_failed" : 'col-md-12 form__content'}
				style={{fontSize: '1.2rem'}}
                value={gender}
                onChange={(e) => {
					setGender(e.target.value)
					errors.gender = ''
				}}
                >
				        <option value="" disabled>{t('reservation.gender')}</option>
                {genderValue.map(item => (
                  <option value={item.value}>{item.value}</option>
                ))}
              </select>
          </div>
              </div>
              <div className="col-md-6">
              <div className="row">
                <div className="login_input">
                <div style={{fontSize: '1.2rem',color: !day || !month || !year ? '#8597a3' : '#482979', textAlign: 'left'}}>{t('reservation.birth-date')}</div>
                </div>
                  <div className="col-md-4">
                  <div className="login_input">
                    <DayPicker />
                  </div>
                  </div>
                  <div className="col-md-4">
                  <div className="login_input">
                    <MonthPicker />

                  </div>
                  </div>
                  <div className="col-md-4">
                  <div className="login_input">
                    <YearPicker />
                  </div>
                  </div>
                </div>  
                    </div>
                </div>
            <div className="row pl-5 pr-5">
                <div className="col-md-6">
            <div className="login_input">
				<input 
				type="email" 
				className="login_input-field" 
				value={userEmail}
				onChange={(e) => setUserEmail(e.target.value)}
				required/>
				<label className="login_input-label ml-0">{t('room_hbt1.emailTitle')}</label>
			</div>
            </div>
            <div className="col-md-6">
            <div className="login_input">
				<input 
				type="text" 
				className="login_input-field" 
				value={phone}
				onChange={(e) => setPhone(e.target.value)}
				required/>
				<label className="login_input-label ml-0">{t('reservation.phone')}</label>
			</div>
          </div>
      </div>
            {/* <div className="row pl-5 pr-5">
                <div className="col-md-6">
                    <div className="login_input">
                        <input 
                        type="password" 
                        className="login_input-field" 
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        required/>
                        <label className="login_input-label ml-0">Password</label>
                    </div>
                </div>
            <div className="col-md-6">
                <div className="login_input">
                    <input 
                    type="password" 
                    className="login_input-field" 
                    value={confirmPassword}
                    onChange={(e) => setConfirmPassword(e.target.value)}
                    required/>
                    <label className="login_input-label ml-0">Confirm Password</label>
                </div>
            </div>
            </div> */}
            <button className="base__btn btn__send">{t('login.save')}</button>
                    </div>
            <button className="base__btn" onClick={() => setModalShow1(true)} style={{backgroundColor: '#ff3131', width: 200, color: "#fff", fontSize:"bold", position:'absolute', right: 20, bottom:20}}>{t("login.delete")}</button>
            <ConfirmDelete show={modalShow1} onHide={() => setModalShow1(false)} />
                  </div>
                </div>
                </div>
            </TabPanel>                                                                                                                                                                                                                                                     
            <TabPanel>
              <div className="service__content">
                <div className="room__content">
                  <div className="room__title">
                    {t("login.coupon")}
                    <img
                      className="style-line"
                      src="https://azumayavietnam.com/image/logo/style-line.png"
                      alt=""
                    />
                  </div>
                  <div className="container">
                    <div className="row">
                      {voucher.map(item => (
                      <div className="col-md-3">
                        <div className="coupon-container">
                          <div className="d-flex align-items-base">
                            {item.type === "BK" && <img src="/hotel-bell.png" style={{width: 50, height: 50}}/> }
                            {item.type === "BF" && <img src="/onigiri.png" style={{width: 50, height: 50}}/> }
                            {item.type === "RT" && <img src="/onsen.png" style={{width: 50, height: 50}}/> }
                            {item.type === "MS" && <img src="/foot-massage.png" style={{width: 50, height: 50}}/> }
                           <h2 style={{backgroundColor: "#fff", color:"#482979", padding: 5, fontWeight:'bold', textTransform:'capitalize'}}>{item.name}</h2>
                          </div>
                           {/* <p className="mt-2">({t('login.coupon-title')})</p> */}
                           <div className="d-flex justify-content-between align-items-center w-100">
                           <p className="mt-2" style={{fontWeight: 'bold'}}>{t("login.valid")}: {formatDate(item.expired)}<br/>{t("login.expired")}: {remainDate(item.expired)} {remainDate(item.expired) <=1 ? 'day' : 'days'}</p>
                           <Link to ='/' className="use-btn">{t("login.use")}</Link>
                           </div>
                        </div>
                      </div>
                      ))}
                    </div>
                  </div>
                </div>
              </div>
            </TabPanel>
            <TabPanel>
              <div className="service__content">
                <div className="room__content">
                  <div className="room__title">
                  {t("login.book-other")}
                    <img
                      className="style-line"
                      src="https://azumayavietnam.com/image/logo/style-line.png"
                      alt=""
                    />
                  </div>
                  {userData ? (
                    <>
                  <div className="room__container ml-md-0 ml-3">
                    <div className="d-flex">
                    <div className="guest-card mr-3">
                    {listGuest.map((item, index) => (
                    <div className="book-container d-flex justify-content-between mb-3">
                      <div className="d-flex align-items-center">
                      <img src ={profilePicture} style={{width: 50, height: 50}}/>
                      <div className="d-flex flex-column justify-content-start ml-3">
                      <p style={{textAlign:'start', fontWeight:'bold'}}>{t("service_massage.guest_name")}: {item.name}</p>
                      <small style={{textAlign:'start'}}>{t("room_hbt1.emailTitle")}: {item.email}</small>
                      <small style={{textAlign:'start'}}>{t("reservation.birth-date")}: {item.birthDate}</small>
                      </div>
                      </div>
                      <div className="d-flex">
                    <div className="d-flex align-items-center ml-3">
                      <button className="edit-btn" onClick={() => {
                        setIndex(index)
                        setModalShow2(true)}
                        }><i class="fa-solid fa-pen-to-square"></i></button>

                    </div>
                    <div className="d-flex align-items-center ml-3 mr-3">
                      <button className="edit-btn" onClick={() => handleClick(index)}><i class="fa-solid fa-bell-concierge"></i></button>
                    </div>
                      </div>
                    </div>
                    ))}
                    </div>
                    </div>
                    </div>
                    <EditGuestInfo show={modalShow2} onHide={() => setModalShow2(false)} />
                    <div className="d-flex justify-content-center mt-3 mb-3 w-100">
                      <button className="edit-btn" onClick={() => setModalShow(true)}><i class="fa-solid fa-user-plus"></i></button>
                      <InputGuestInfo show={modalShow} onHide={() => setModalShow(false)} />
                    </div>
                    </>
                  ): ''}
                  </div>
                </div>
            </TabPanel>
            <TabPanel>
              <div className="service__content">
                <div className="room__content">
                  <div className="room__title">
                    {t("login.book-history")}
                    <img
                      src="https://azumayavietnam.com/image/logo/style-line.png"
                      alt=""
                      className="style-line"
                    />
                    <div className="container">
                      <div className="row">
                        <div className="col-md-4 pb-3">
                          <div className="d-flex">
                            <div className="d-flex flex-column justify-content-center align-items-center history-container pl-0 p-2">
                            <div className="history-date">12/05/2024</div>
                            <div className="history-hour">20:00</div>
                            </div>
                           <div className="history-container pr-2" style={{width: 400}}>
                            <div className="d-flex justify-content-between">
                              <div className="d-flex">
                              <div className="pr-2"style={{width: 100}}>{t("login.bookingId")}:</div>
                              <div className="history-text">847593342</div>
                              </div>
                              <div className="booking-status-completed pr-3">Completed</div>
                            </div>
                            <div className="d-flex">
                            <div className="pr-2" style={{width: 100}}>{t("login.branch")}:</div>
                            <div className="history-text">Azumaya Kim Ma 3</div>
                            </div>
                            <div className="d-flex">
                            <div className="pr-2" style={{width: 100}}>{t("room_hbt1.type")}:</div>
                            <div className="history-text">EXECUTIVE</div>
                            </div>
                            <div className="d-flex">
                            <div className="pr-2" style={{width: 100}}>{t("login.schedule")}: </div>
                            <div className="history-text">1 night</div>
                            </div>
                            <div className="d-flex">
                            <div className="pr-2" style={{width: 100}}>{t("login.price")}:</div>
                            <div className="history-text">1.342.124 VNĐ</div>
                            </div>
                           </div>
                        </div>
                        </div>

                        <div className="col-md-4 pb-3">
                          <div className="d-flex">
                            <div className="d-flex flex-column justify-content-center align-items-center history-container pl-0 p-2">
                            <div className="history-date">12/05/2024</div>
                            <div className="history-hour">20:00</div>
                            </div>
                           <div className="history-container pr-2" style={{width: 400}}>
                            <div className="d-flex justify-content-between">
                              <div className="d-flex">
                              <div className="pr-2"style={{width: 100}}>{t("login.bookingId")}:</div>
                              <div className="history-text">847593342</div>
                              </div>
                              <div className="booking-status-canceled pr-3">Canceled</div>
                            </div>
                            <div className="d-flex">
                            <div className="pr-2" style={{width: 100}}>{t("login.branch")}:</div>
                            <div className="history-text">Azumaya Kim Ma 3</div>
                            </div>
                            <div className="d-flex">
                            <div className="pr-2" style={{width: 100}}>{t("room_hbt1.type")}:</div>
                            <div className="history-text">EXECUTIVE</div>
                            </div>
                            <div className="d-flex">
                            <div className="pr-2" style={{width: 100}}>{t("login.schedule")}: </div>
                            <div className="history-text">1 night</div>
                            </div>
                            <div className="d-flex">
                            <div className="pr-2" style={{width: 100}}>{t("login.price")}:</div>
                            <div className="history-text">1.342.124 VNĐ</div>
                            </div>
                           </div>
                        </div>
                        </div>

                        <div className="col-md-4 pb-3">
                          <div className="d-flex">
                            <div className="d-flex flex-column justify-content-center align-items-center history-container pl-0 p-2">
                            <div className="history-date">12/05/2024</div>
                            <div className="history-hour">20:00</div>
                            </div>
                           <div className="history-container pr-2" style={{width: 400}}>
                            <div className="d-flex justify-content-between">
                              <div className="d-flex">
                              <div className="pr-2"style={{width: 100}}>{t("login.bookingId")}:</div>
                              <div className="history-text">847593342</div>
                              </div>
                              <div className="booking-status-no-show pr-3">No Show</div>
                            </div>
                            <div className="d-flex">
                            <div className="pr-2" style={{width: 100}}>{t("login.branch")}:</div>
                            <div className="history-text">Azumaya Kim Ma 3</div>
                            </div>
                            <div className="d-flex">
                            <div className="pr-2" style={{width: 100}}>{t("room_hbt1.type")}:</div>
                            <div className="history-text">EXECUTIVE</div>
                            </div>
                            <div className="d-flex">
                            <div className="pr-2" style={{width: 100}}>{t("login.schedule")}: </div>
                            <div className="history-text">1 night</div>
                            </div>
                            <div className="d-flex">
                            <div className="pr-2" style={{width: 100}}>{t("login.price")}:</div>
                            <div className="history-text">1.342.124 VNĐ</div>
                            </div>
                           </div>
                        </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </TabPanel>
            <TabPanel>
              <div className="service__content">
                <div className="room__content">
                  <div className="room__title pre-line">
                  {t("login.thankyou")}
                    <img
                      src="https://azumayavietnam.com/image/logo/style-line.png"
                      alt=""
                      className="style-line"
                    />
                    <div className="container">
                      <div className="row">
                        <div className="col-md-12">
                        <button className="base__btn" onClick={handleLogout} style={{backgroundColor: '#ff3131', width: 200, color: "#fff", fontSize:"bold"}}>{t("login.logout")}</button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </TabPanel>
          </Tabs>
        </TabPanel>
      </Tabs>   
            </div>
        </div>
            </>
        
    )
}